<template>
  <div
    class="w-full h-full fixed left-0 top-0 z-[100] bg-[#4B894A] bg-opacity-80 flex flex-col justify-center text-center"
    :class="modalData.type === 'withdrawPay' ? 'px-[10%]' : 'px-[15%] '"
  >
    <div class="px-4 pt-6 bg-white rounded-3xl">
      <!-- 닫기버튼 필요한 모달 v-if에 추가하면 됨 -->

      <!-- 닉네임편집 -->
      <div
        class="flex justify-end -mb-3"
        v-if="modalData.type == 'edit'"
        @click="setModalToggle"
      >
        <img
          src="@/assets/images/material_symbols_close_rounded.png"
          alt=""
          class="w-[30px]"
        />
      </div>
      <h1 class="font-bold text-[20px] text-[#444]">{{ modalData.title }}</h1>
      <div v-if="modalData.type == 'withdrawPay'" class="text-[0.8rem] py-4 font-regular">
        <div class="flex text-left">
          <div class="w-[3px] h-[3px] bg-[#444] rounded-full mr-2 flex"></div>
          <div>{{ $t('modal.provision1') }}</div>

          <div class="ml-auto">{{ $t('modal.provision2') }}</div>
        </div>
        <div class="flex text-left">
          <div class="w-[3px] h-[3px] bg-[#444] rounded-full mr-2 flex"></div>
          <div>{{ $t('modal.provision3') }}</div>
          <div class="ml-auto">{{ $t('modal.provision4') }}</div>
        </div>
        <div class="flex text-left">
          <div class="w-[3px] h-[3px] bg-[#444] rounded-full mr-2 flex"></div>
          <div>{{ $t('modal.provision5') }}</div>
          <div class="ml-auto">{{ $t('modal.provision6') }}</div>
        </div>
        <div class="text-left text-[#4B894A] mt-4">
          * {{ $t('modal.provision7') }}
        </div>
        <button @click="closeModal" class="mt-4 text-[13px] font-bold">{{ $t('common.close') }}</button>
      </div>
      <!-- <input
        type="text"
        class="w-full border-b border-[#686f72] mt-4 outline-none text-[15px] placeholder:text-[#c3c3c3]"
        placeholder="닉네임을 입력해주세요"
        maxlength="12"
        v-model="nickInput"
        v-if="modalData.type == 'edit'"
      /> -->
      <textarea
        type="text"
        class="w-full p-2 bg-[#f0f0f3] mt-4 h-24 overflow-y-scroll leading-[20px]"
        v-if="modalData.type == 'cancel'"
        :value="$t('modal.bankNo')"
      />
      <p class="text-right text-[12px] mt-2" v-if="modalData.type == 'edit'">
        {{ nickInput.length }}/12
      </p>
      <div class="text-[15px]">
        <!-- 괄호 사용해서 바인딩하면 줄바꿈이 안되서 v-html 사용 -->
        <p v-html="desc"></p>
      </div>
      <!-- 아이디 찾기 모달 -->
      <div v-if="modalData.type == 'idFind'">
        {{ userId }}
      </div>
      <div class="flex flex-wrap font-bold">
        <button
          class="basis-1/2 py-4 text-[#d2d2d2]"
          @click="closeModal"
          v-if="modalData.type == 'retract' || modalData.type == 'logout'"
        >
        {{ $t('common.cancel') }}
        </button>
        <button
          class="basis-1/2 py-4 text-[#d2d2d2]"
          @click="accountRetract"
          v-if="modalData.type == 'retract'"
        >
        {{ $t('setting.dropout') }}
        </button>
        <button
          class="basis-1/2 py-4 text-[#4B894A]"
          @click="logOutBtn"
          v-if="modalData.type == 'logout'"
        >
        {{ $t('modal.confirm') }}
        </button>
        <button
          class="basis-1/2 py-4 text-[#d2d2d2]"
          @click="goWithdrawPage"
          v-if="modalData.type == 'withdraw_success'"
        >
        {{ $t('withdraw.list') }}
        </button>
        <button
          class="basis-1/2 py-4 text-[#4B894A]"
          @click="goMainPage"
          v-if="modalData.type == 'withdraw_success'"
        >
        {{ $t('modal.goMain') }}
        </button>
        <button
          class="basis-full py-4 text-[#4B894A]"
          @click="closeModal(newNickName)"
          v-if="
            modalData.type == 'edit' ||
            modalData.type == 'info_blank' ||
            modalData.type == 'withdraw_fail' ||
            modalData.type == 'cancel' ||
            modalData.type == 'nomal' ||
            modalData.type == 'no_user' ||
            modalData.type == 'idFind'
          "
        >
        {{ $t('modal.confirm') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { store } from "@/store";
import create from "vue-zustand";
import ReqApi from "@/components/modules/ReqApi.js";
export default {
  name: "AlertModal",
  setup() {
    const useStore = create(store);
    const {
      setModalToggle,
      setModalData,
      modalData,
      setModalName,
      setModalOpen,
      setRefresh,
      setSessionOver,
      setSideToggle,
    } = useStore();
    return {
      setModalToggle,
      setModalData,
      modalData,
      setModalName,
      setModalOpen,
      setRefresh,
      setSessionOver,
      setSideToggle,
    };
  },
  data() {
    return {
      sideShow: false,
      today_center: true,
      deactivate: false,
      nickInput: "",
      tStyle: {
        failText: "text-red-400",
      },
      userId: ''
    };
  },
  computed: {
    desc() {
      return this.modalData.desc.replace("\n", "<br />");
    },
  },
  methods: {
    goPointPage() {
      this.$router.push({
        name: "PointDetail",
      });
      this.setModalToggle();
    },
    goWithdrawPage() {
      this.$router.push({
        name: "WithdrawList",
      });
      this.setModalToggle();
    },
    goLoginPage() {
      this.$router.push({
        name: "UserLogin",
      });
      this.setModalToggle();
    },
    goMainPage() {
      this.$router.push({
        name: "MainHome",
      });
      this.setModalToggle();
    },
    inputState() {
      var regExp = /[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi;
      if (regExp) {
        alert("zz");
      }
    },
    accountDelete() {
      this.deactivate = !this.deactivate;
    },
    logOutBtn() {
      this.setSessionOver();
      this.setModalToggle();
      this.setSideToggle();
    },
    closeModal() {
      if (
        this.modalData?.type === "info_blank" ||
        this.modalData?.type === "withdraw_success" ||
        this.modalData?.type === "withdraw_fail" ||
        this.modalData?.type === "cancel" ||
        this.modalData?.type === "retract" ||
        this.modalData?.type === "nomal" ||
        this.modalData?.type == "no_user" ||
        this.modalData?.type == 'withdrawPay' ||
        this.modalData?.type == 'idFind'
      ) {
        this.setModalToggle();
      } else if (this.modalData?.type === "edit") {
        this.renameApi();
        // store.newNickName(newNickName.value)

        // if (typeof this.modalData?.onClose === "function") {
        //   this.modalData.onClose(this.modalData.nickName);
        // }
      }

      //isReload가 true일 경우 새로고침
      // if(this.alertData.isReload){
      //   location.reload();
      //   return;
      // }

      // if (this.alertData?.type === 'setEditOpen') {
      //   this.setApiCall();
      //   this.setModalToggle();
      //   return;
      // } else if (
      //   this.alertData?.type &&
      //   this.alertData.type !== '' &&
      //   this.alertData.type !== 'order'
      // ) {
      //   this.setModalName(this.alertData.type);
      //   this.setModalOpen()
      //   return;
      // }

      // let popupName =
      //   this.alertData.popupName &&
      //   this.alertData.popupName !== '' &&
      //   this.alertData.popupName;
      // let beforeData = this.alertData.beforeData && this.alertData.beforeData;

      // if(popupName || beforeData){
      //   if(popupName) this.setModalName(popupName);
      //   if(beforeData) this.setAlertData(beforeData);
      //   this.setAlertOpen()
      // }
      // else {
      //   this.setAlertData({});
      //   this.setAlertOpen();
      //   this.setRefresh('wallet')
      // }
    },
    async accountRetract() {
      if(this.modalData?.type === "retract") {
      let result = await ReqApi('/api/home/v1/withdraw');

      if (!result) return;

      let { status, message } = result.API_CODE;

      if (status == '0000') {
        this.setModalToggle();
        this.$router.push({
          name: "UserLogin"
        });

        return;
      }
      }
    },
    // async renameApi() {
    //   const data = {
    //     rename: this.nickInput,
    //   };
    //   // 메인 api호출
    //   let result = await ReqApi("/api/main/v1/rename", data);
    //   // 결과 값이 없을 때 리턴으로 호출 종료
    //   if (!result) return;

    //   let { status } = result.API_CODE;
    //   if (status === "L402") {
    //     localStorage.removeItem("medicubo_token");
    //     localStorage.removeItem("medicubo_rtoken");
    //   } else if (status === "0000") {
    //     this.setRefresh("MainHome");
    //     this.setModalToggle();
    //   } else if (status == "9999") {
    //     alert("시스템처리중 오류");
    //   }
    // },
  },
};
</script>
<style scoped></style>
