import { createStore } from "zustand/vanilla";
import ReqApi from "./components/modules/ReqApi";

export const store = createStore((set) => ({
  depositor: "",
  account: "",
  phoneNum: "",
  bank: "",
  nickName: "",
  newNickName: "",
  isSelectOpen: false,
  selectedValue: "all",
  isDateOpen: false,
  isDateOpen2: false,
  selectedDate: "วันนี้",
  selectedDate2: "1 เดือน",
  // modal
  whatModal: "",
  setModalName(modalName) {
    set(() => ({
      whatModal: modalName,
    }));
  },
  isModalOpen: false,
  setModalToggle() {
    set((state) => ({
      isModalOpen: !state.isModalOpen,
    }));
  },
  modalData: {
    title: "",
    desc: "",
    type: "",
  },
  setModalData(data) {
    set(() => ({
      modalData: data,
    }));
  },
  setModalOpen() {
    set((state) => ({
      modalOpen: !state.modalOpen,
    }));
  },
  // sidemenu toggle
  isSideOpen: false,
  setSideToggle() {
    set((state) => ({
      isSideOpen: !state.isSideOpen,
    }));
  },
  //pointDetail page select modal close
  setSelectCancelToggle() {
    set((state) => ({
      isSelectOpen: !state.isSelectOpen,
    }));
  },
  setSelectToggle(data) {
    set((state) => ({
      isSelectOpen: !state.isSelectOpen,
      selectedValue: data,
    }));
  },
  //pointDetail page date modal close
  setDateToggle() {
    set((state) => ({
      isDateOpen: !state.isDateOpen,
    }));
  },
  //output history page date modal close
  setDateToggle2() {
    set((state) => ({
      isDateOpen2: !state.isDateOpen2,
    }));
  },
  // pointDetail page date select
  setSelectDate(data) {
    set(() => ({
      selectedDate: data,
    }));
  },
  // pointDetail page date select
  setSelectDate2(data) {
    set(() => ({
      selectedDate2: data,
    }));
  },
  // userjoin 에서 입력한 닉네임 저장
  setNickName(newNickName) {
    set(() => ({
      nickName: newNickName,
    }));
  },
  // 입금주
  setDepositor(depositor) {
    set(() => ({
      depositor: depositor,
    }));
  },
  // 계좌번호
  setAccount(account) {
    set(() => ({
      account: account,
    }));
  },
  setPhoneNum(phoneNum) {
    set(() => ({
      phoneNum: phoneNum,
    }));
  },
  setBank(bank) {
    set(() => ({
      bank: bank,
    }));
  },
  // 출금포인트 저장
  withdrawAmount: null,
  setWithdrawAmount(amount) {
    set(() => ({
      withdrawAmount: amount,
    }));
  },
  // 이체완료 저장
  transferData: null,
  setTransferData(data) {
    set(() => ({
      transferData: data
    }));
  },
  clearTransfer() {
    set(() => ({
      transferData: null
    }));
  },

  // 최초 로그인(회원가입)때 userjoin으로 화면 전환 시 필요한 데이터 저장
  // goJoinData sample
  // { login_type: "google",
  // uid: 'jone@jone.com',
  // upw: 비밀번호 암호화 데이터 }
  goJoinData: {},
  setGoJoinData(data) {
    set(() => ({
      goJoinData: data,
    }));
  },
  terms: [],
  privacy: [],
  setTermData(data) {
    set(() => ({
      terms: data.terms,
      privacy: data.privacy,
    }));
  },
  // 본인인증 정보 저장
  // name: '',
  // phone: '',
  // birth: '',
  // gender: '',
  // token: '',
  authData: {},
  // joinToken: '',
  setAuthData(data) {
    set(() => ({
      authData: data,
    }));
  },
  // setJoinToken(data){
  //   set(() => ({
  //     joinToken : data
  //   }))
  // },

  // 메인화면 유저정보 저장
  userInfo: {},
  userType: {},
  setUserInfo(data, type) {
    set(() => ({
      userInfo: data,
      userType: type,
    }));
  },
  // refresh
  refresh: "",
  setRefresh(data) {
    set(() => ({
      refresh: data,
    }));
  },
  // 포인트 상세보기 api data 저장
  pointList: [],
  mypoint: "",
  setPointList(list, point) {
    set(() => ({
      pointList: list,
      mypoint: point,
    }));
  },
  // 포인트 출금내역 api data 저장
  outputList: [],
  setOutputList(list) {
    set(() => ({
      outputList: list,
    }));
  },
  // faq 목록 api data 저장
  faqList: [],
  setFaqList(list) {
    set(() => ({
      faqList: list,
    }));
  },
  // 잇젬 소식 api data 저장
  newsList: [],
  newsSeq: "",
  setNewsList(list, seq) {
    set(() => ({
      newsList: list,
      newsSeq: seq,
    }));
  },
  // 키오스크 api data 저장
  kioskList: [],
  setKioskList(list) {
    set(() => ({
      kioskList: list,
    }));
  },

  // 세션 만료 처리
  isSessionOver: false,
  setSessionOver() {
    set(() => ({
      isSessionOver: true,
    }));
  },
  setSessionOn() {
    set(() => ({
      isSessionOver: false,
    }));
  },

  // 포인트 상세보기 시작일 종료일 세팅
  startDate: null,
  endDate: null,
  setSearchDate(s, e) {
    set(() => ({
      startDate: s,
      endDate: e,
    }));
  },
  // 포인트 출금내역 시작일 종료일 세팅
  startDate2: null,
  endDate2: null,
  setSearchDate2(s, e) {
    set(() => ({
      startDate2: s,
      endDate2: e,
    }));
  },
  fromPage: '',
  setFromPage(page) {
    set(() => ({
      fromPage: page
    }))
  },
  // 비밀번호찾기 id저장
  pwChangeId: '',
  setPwChangeId(data) {
    set(() => ({
      pwChangeId: data
    }))
  },
  // 비밀번호찾기 auth token
  pwAuthToken: '',
  setPwAuthToken(data) {
    set(() => ({
      pwAuthToken: data
    }))
  },
  // 본인인증 정보
  name: "",
  birth: "",
  phoneNumber: "",
  setAuthInfo: (name, birth, phoneNumber) =>
    set((state) => ({
      ...state,
      name,
      birth,
      phoneNumber,
    })),
  // audition
  selectedAuditionUuid: "",
  selectedNationCode: "",
  myTicketCount: 0,
  async loadTicketCount() {
    let result = await ReqApi("/api/audition/v1/request_my_ticket_cnt");
    // 결과 값이 없을 때 리턴으로 호출 종료
    if (!result) return;
    // response 상태값과 메세지 추출
    let { status, message } = result.API_CODE;

    if (status == "0000") {
      set(() => ({
        myTicketCount: Number(result.myTicketCnt)
      }))
    }

    if (status === "L402") {
    }
  },
  isTicketModalOn: false,
  selectedStarId: "",
  isVoteModalOn: false,
  selectedAdsId: "",
  isAdsCompleteModalOn: false,
  rewardTicketCount: 0
}));
