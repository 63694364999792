// api호출할때 쓰는 모듈

import axios from "axios";
import AesDecrypt from "@/components/modules/AesDecrypt";
import AesEncrypt from "@/components/modules/AesEncrypt";

const ReqApi = async (uri, reqData, headers, language) => {
  let encryptedData = JSON.stringify({
    data: AesEncrypt(reqData),
  });
  try {
    let response = await axios({
      method: "post",
      url: `${process.env.VUE_APP_MAIN_API_URI}${uri}`,
      headers: {
        "Content-Type": "application/json",
        language: language || "kr",
        apikey: process.env.VUE_APP_MAIN_API_KEY,
        Authorization: `Bearer ${localStorage.getItem("medicubo_token")}`,
        Validation: `Bearer ${localStorage.getItem("medicubo_rtoken")}`,
        ...headers,
      },
      data: encryptedData,
    });
    if (response.status !== 200) return false;
    let decrtypedResult = JSON.parse(AesDecrypt(response.data.data.toString()));

    /*if (decrtypedResult.has("AUTH")) {
      let AUTH = decrtypedResult.AUTH;
      if (AUTH.has("token")) {
        localStorage.setItem("medicubo_token", AUTH.token);
      }
      if (AUTH.has("rtoken")) {
        localStorage.setItem("medicubo_rtoken", AUTH.rtoken);
      }
    }

    if (decrtypedResult.has("USER")) {
      let AUTH = decrtypedResult.AUTH;
      if (AUTH.has("token")) {
        localStorage.setItem("medicubo_token", AUTH.token);
      }
      if (AUTH.has("rtoken")) {
        localStorage.setItem("medicubo_rtoken", AUTH.rtoken);
      }
    }*/
    
    //console.log(decrtypedResult);
    return decrtypedResult;
  } catch (e) {
    return false;
  }
};

export default ReqApi;
