<template>
  <div>
    <div v-if="bAudition" class="flex w-full items-center justify-center">
      <button
        class="w-[20rem] py-2 mb-4 text-white bg-[#4B894A] rounded-full text-[14px] font-medium drop-shadow-lg"
        @click="onAudition"
      >
        {{ $t("menuBar.audition") }}
      </button>
    </div>
    <div
      class="flex justify-between items-center text-center py-4 bg-[#f0f0f3] text-[13px] text-[#a0a0a0] drop-shadow-[0_5px_10px_rgba(128,128,128,0.20)] border-t-2 border-white border-opacity-10"
    >
      <div class="basis-1/4">
        <router-link to="/">
          <img
            src="@/assets/images/symbols_home.png"
            alt=""
            class="w-[1.6rem] mx-auto"
            v-if="$route.path === '/'"
          />
          <img
            src="@/assets/images/symbols_home_u.png"
            alt=""
            class="w-[1.6rem] mx-auto"
            v-else
          />
          <div>{{ $t("menuBar.home") }}</div>
        </router-link>
      </div>
      <div class="basis-1/4">
        <router-link to="/point">
          <img
            src="@/assets/images/symbols_coin.png"
            alt=""
            class="w-[1.6rem] mx-auto"
            v-if="$route.path === '/point'"
          />
          <img
            src="@/assets/images/symbols_coin_u.png"
            alt=""
            class="w-[1.6rem] mx-auto"
            v-else
          />
          <div>{{ $t("menuBar.point") }}</div>
        </router-link>
      </div>
      <div class="basis-1/4">
        <router-link to="/transfer">
          <img
            src="@/assets/images/symbols_transfer.png"
            alt=""
            class="w-[1.6rem] mx-auto"
            v-if="$route.path === '/transfer'"
          />
          <img
            src="@/assets/images/symbols_transfer_u.png"
            alt=""
            class="w-[1.6rem] mx-auto"
            v-else
          />
          <div>{{ $t("menuBar.transfer") }}</div>
        </router-link>
      </div>
      <!--<div class="basis-1/5">
        <router-link to="/location">
          <img src="@/assets/images/symbols_map.png" alt="" class="w-[1.6rem] mx-auto" v-if="$route.path === '/location'">
        <img src="@/assets/images/symbols_map_u.png" alt="" class="w-[1.6rem] mx-auto" v-else>
          <div>{{ $t('menuBar.location') }}</div>
        </router-link>
      </div>-->
      <div class="basis-1/4">
        <router-link to="/menu">
          <img
            src="@/assets/images/symbols_menu.png"
            alt=""
            class="w-[1.6rem] mx-auto"
            v-if="$route.path === '/menu'"
          />
          <img
            src="@/assets/images/symbols_menu_u.png"
            alt=""
            class="w-[1.6rem] mx-auto"
            v-else
          />
          <div>{{ $t("menuBar.menu") }}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ReqApi from "@/components/modules/ReqApi";

export default {
  name: "MenuBar",
  data() {
    return {
      activeLink: "",
      auditionState: "end",
      bAudition: false,
    };
  },
  methods: {
    setActiveLink(link) {
      this.activeLink = link;
    },
    async checkAudition() {
      let result = await ReqApi("/api/audition/v1/request_audition_info");
      // 결과 값이 없을 때 리턴으로 호출 종료
      if (!result) return;
      // response 상태값과 메세지 추출
      let { status, message } = result.API_CODE;

      if (status == "0000") {
        this.auditionState = result.auditionInfo.state;
        if (this.auditionState == "end") {
          this.bAudition = false;
        } else {
          this.bAudition = true;
        }
      }

      if (status === "L402") {
      }
    },
    onAudition() {
      console.log("onAudition: " + this.auditionState);
      if (this.auditionState == "ready") {
        this.$router.push({
          name: "AuditionReady",
        });
      } else if (this.auditionState == "open") {
        this.$router.push({
          name: "AuditionMain",
        });
      } else if (this.auditionState == "result") {
        this.$router.push({
          name: "AuditionResult",
        });
      }
    }
  },
  watch: {
    $route() {
      this.activeLink = this.$route.path;
    },
  },
  mounted() {
    this.checkAudition();
  }
};
</script>

<style scoped>
.router-link-active {
  color: #4b894a;
}
</style>
<!--c40073 4B894A-->
