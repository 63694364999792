<template>
  <div class="w-full h-full">
    <Modal v-if="isModalOpen" />
    <div class="w-full h-full" v-if="loading">
      <MenuBar
        class="fixed bottom-0 w-full z-[20]"
        v-if="
          $route.path === '/' ||
          $route.path === '/point' ||
          $route.path === '/transfer' ||
          $route.path === '/location' ||
          $route.path === '/menu'
        "
      />
      <router-view class="w-full h-full font-regular text-[15px]" />
    </div>
    <SplashView class="fixed w-full h-screen" v-if="!loading" />
  </div>
</template>

<script>
import SplashView from "./components/SplashView";
import MenuBar from "@/components/MenuBar.vue";
import Modal from "@/components/AlertModal.vue";
import ReqApi from "@/components/modules/ReqApi";
import create from "vue-zustand";
import { store } from "@/store";

export default {
  name: "App",
  data() {
    return {
      loading: false,
    };
  },
  components: {
    SplashView,
    Modal,
    MenuBar,
  },
  setup() {
    const useStore = create(store);
    const {
      isModalOpen,
      setModalToggle,
      setModalData,
      modalData,
      setModalName,
      setModalOpen,
      isSessionOver,
      setSessionOn,
    } = useStore();
    return {
      setModalToggle,
      setModalData,
      modalData,
      setModalName,
      setModalOpen,
      isModalOpen,
      isSessionOver,
      setSessionOn,
    };
  },
  mounted() {
    this.loadScript()
  },
  created() {
    setTimeout(() => {
      this.checkToken();
    }, 1000);
    // if (localStorage.getItem("medicubo_token") == null) {
    //   this.$router.push({
    //     name: "UserLogin"
    //   });
    // } else {
    //   this.setSessionOn();
    // }
  },
  watch: {
    // 로컬 토큰 검증

    isSessionOver(a) {
      if (a == true) {
        this.setSessionOn();
        localStorage.removeItem("medicubo_token");
        localStorage.removeItem("medicubo_rtoken");
        this.$router.push({
          name: "UserLogin",
        });
      }
    },
  },
  methods: {
    async checkToken() {
      const userLocalInfo = localStorage.getItem("medicubo_user") == null ? '' :JSON.parse(localStorage.getItem("medicubo_user"));
      const localToken = localStorage.getItem('medicubo_token')

      if (userLocalInfo == '' && localToken == null) {
        // this.$router.push({
        //   name: "UserLogin",
        // });
        this.loading = true;
        return;
      }

      // 메인화면 api로 session 만료 체크
      const result = await ReqApi("/api/home/v1/main");

      if (!result) {
        this.$router.push({
          name: "UserLogin",
        });
        this.loading = true;
        return;
      }

      const { status } = result.API_CODE;

      if (status == "0000") {
        this.$router.push({
          name: "MainHome",
        });
        this.loading = true;
      } else if (status == "L402") {
        localStorage.removeItem('medicubo_token')
        localStorage.removeItem('medicubo_rtoken')
        const userData = {
          uid: userLocalInfo[0],
          upw: userLocalInfo[1]
        };
        this.autoLogin(userData);
      } else {
        this.$router.push({
          name: "UserLogin",
        });
        this.loading = true;
      }
    },
    async autoLogin(userData) {
      const result = await ReqApi("/api/login/v1/login", userData);

      if (!result) {
        this.$router.push({
          name: "UserLogin",
        });
        this.loading = true;
        return;
      }

      const { status } = result.API_CODE;
      if (status == "0000") {
        localStorage.setItem("medicubo_token", result.user.token);
        localStorage.setItem("medicubo_rtoken", result.user.rtoken);
        this.$router.push({
          name: "MainHome",
        });
        this.loading = true;
      }
      else {
        this.$router.push({
          name: "UserLogin",
        });
        this.loading = true;
      }
    },
    loadScript() {
      const script = document.createElement("script");
      const apiKey = process.env.VUE_APP_KAKAOMAP_KEY;
      script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${apiKey}&autoload=false`;
      
      script.onload = () => {
        kakao.maps.load(() => {
          // this.loadMap();
        });
      };
      document.head.appendChild(script);
    },
  },
};
</script>

<style>
body,
html,
#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f3;
  color: #686f72;
  letter-spacing: -0.5px;
}
input {
  opacity: 1;
-webkit-text-fill-color: #686f72;
}
</style>
