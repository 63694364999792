import { createWebHistory, createRouter } from "vue-router";

const routes = [
  // 팝업화면
  {
    path: "/popup",
    name: "PopUp",
    component: () =>
      import(/* webpackChunkName: "popup" */ "./components/PopUp.vue"),
  },
  // 로그인화면
  {
    path: "/login",
    name: "UserLogin",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "./components/login/UserLogin.vue"
      ),
  },
  //  회원가입 설정
  {
    path: "/join",
    name: "UserJoin",
    component: () =>
      import(/* webpackChunkName: "Join" */ "./components/login/UserJoin.vue"),
  },
  {
    path: "/idfind",
    name: "IdFind",
    component: () =>
      import(/* webpackChunkName: "idfind" */ "./components/login/IdFind.vue"),
  },
  {
    path: "/pwfind",
    name: "PwFind",
    component: () =>
      import(/* webpackChunkName: "pwfind" */ "./components/login/PwFind.vue"),
  },
  {
    path: "/pwchange",
    name: "PwChange",
    component: () =>
      import(
        /* webpackChunkName: "pwchange" */ "./components/login/PwChange.vue"
      ),
  },
  // 약관동의
  {
    path: "/agree",
    name: "AgreeTerm",
    component: () =>
      import(/* webpackChunkName: "Join" */ "./components/login/AgreeTerm.vue"),
  },
  // 서비스이용약관
  {
    path: "/term",
    name: "TermService",
    component: () =>
      import(
        /* webpackChunkName: "Join" */ "./components/login/TermService.vue"
      ),
  },
  // 개인정보처리방침
  {
    path: "/policy",
    name: "PersonalPolicy",
    component: () =>
      import(
        /* webpackChunkName: "Join" */ "./components/login/PersonalPolicy.vue"
      ),
  },
  // 본인인증
  {
    path: "/auth",
    name: "SelfAuth",
    component: () =>
      import(/* webpackChunkName: "auth" */ "./components/login/SelfAuth.vue"),
  },
  {
    path: "/",
    name: "MainHome",
    component: () =>
      import(/* webpackChunkName: "main" */ "./components/main/MainHome.vue"),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  // 잇젬소식
  {
    path: "/news",
    name: "newsView",
    component: () =>
      import(/* webpackChunkName: "news" */ "./components/main/components/newsView.vue"),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  // 잇젬소식 상세
  {
    path: "/news/:id",
    name: "newsDetail",
    component: () =>
      import(/* webpackChunkName: "news" */ "./components/main/components/newsDetail.vue"),
  },
  // 젬스포인트
  {
    path: "/point",
    name: "GemsPoint",
    component: () =>
      import(
        /* webpackChunkName: "point" */ "./components/gemPoint/GemsPoint.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  // 잇젬위치
  {
    path: "/location",
    name: "ItgemLocation",
    component: () =>
      import(
        /* webpackChunkName: "location" */ "./components/location/ItgemLocation.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  // 사이드메뉴
  {
    path: "/menu",
    name: "SideMenu",
    component: () =>
      import(/* webpackChunkName: "menu" */ "./components/side/SideMenu.vue"),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  // medicubo란?
  {
    path: "/medicubo",
    name: "WhatIsMedicubo",
    component: () =>
      import(
        /* webpackChunkName: "medicubo" */ "./components/side/WhatIsMedicubo.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  // 공지사항
  {
    path: "/notice",
    name: "NoticeView",
    component: () =>
      import(
        /* webpackChunkName: "notice" */ "./components/side/NoticeView.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  // 공지사항상세
  {
    path: "/noticedetail",
    name: "NoticeDetail",
    component: () =>
      import(
        /* webpackChunkName: "noticedetail" */ "./components/side/NoticeDetail.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  // FAQ
  {
    path: "/faq",
    name: "CollectQuestion",
    component: () =>
      import(
        /* webpackChunkName: "faq" */ "./components/side/CollectQuestion.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  // FAQ상세
  {
    path: "/faq/:id",
    name: "QuestionDetail",
    component: () =>
      import(
        /* webpackChunkName: "faqdetail" */ "./components/side/QuestionDetail.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  // 환경설정
  {
    path: "/setting",
    name: "SystemSetting",
    component: () =>
      import(
        /* webpackChunkName: "setting" */ "./components/side/SystemSetting.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  {
    path: "/info",
    name: "MyInfo",
    component: () =>
      import(/* webpackChunkName: "info" */ "./components/side/MyInfo.vue"),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  {
    path: "/detail",
    name: "PointDetail",
    component: () =>
      import(
        /* webpackChunkName: "detail" */ "./components/gemPoint/PointDetail.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  {
    path: "/withdraw1",
    name: "WithDraw1",
    component: () =>
      import(
        /* webpackChunkName: "withdraw1" */ "./components/gemPoint/WithDraw1.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  // 출금내역
  {
    path: "/withdraw",
    name: "WithdrawList",
    component: () =>
      import(
        /* webpackChunkName: "withdraw" */ "./components/gemPoint/WithdrawList.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  // 출금이용약관
  {
    path: "/term",
    name: "WithdrawTerm",
    component: () =>
      import(
        /* webpackChunkName: "term" */ "./components/gemPoint/WithdrawTerm.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  {
    path: "/withdraw2",
    name: "WithDraw2",
    component: () =>
      import(
        /* webpackChunkName: "withdraw2" */ "./components/gemPoint/WithDraw2.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  {
    path: "/withdraw3",
    name: "WithDraw3",
    component: () =>
      import(
        /* webpackChunkName: "withdraw3" */ "./components/gemPoint/WithDraw3.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  //이체
  {
    path: "/transfer",
    name: "Transfer",
    component: () =>
      import(
        "./components/transfer/Transfer.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  {
    path: "/transfer/scan",
    name: "TransferScan",
    component: () =>
      import(
        "./components/transfer/Scan.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  {
    path: "/transfer/ready",
    name: "TransferReady",
    component: () =>
      import(
        "./components/transfer/Ready.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  {
    path: "/transfer/complete",
    name: "TransferComplete",
    component: () =>
      import(
        "./components/transfer/Complete.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  {
    path: "/transfer/fail",
    name: "TransferFail",
    component: () =>
      import(
        "./components/transfer/Fail.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  // start
  {
    path: "/splash",
    name: "SplashView",
    component: () =>
      import(/* webpackChunkName: "splash" */ "./components/SplashView.vue"),
  },
  //audition
  {
    path: "/audition/ready",
    name: "AuditionReady",
    component: () =>
      import(
        /* webpackChunkName: "point" */ "./components/audition/AuditionReady.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  {
    path: "/audition/join",
    name: "AuditionJoin",
    component: () =>
      import(
        /* webpackChunkName: "point" */ "./components/audition/AuditionJoin.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  {
    path: "/audition/main",
    name: "AuditionMain",
    component: () =>
      import(
        /* webpackChunkName: "point" */ "./components/audition/AuditionMain.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  {
    path: "/audition/star",
    name: "AuditionStar",
    component: () =>
      import(
        /* webpackChunkName: "point" */ "./components/audition/AuditionStar.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  {
    path: "/audition/ticket/buy/point",
    name: "AuditionTicketBuyPoint",
    component: () =>
      import(
        /* webpackChunkName: "point" */ "./components/audition/AuditionTicketBuyPoint.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  {
    path: "/audition/ticket/buy/ads",
    name: "AuditionTicketBuyAds",
    component: () =>
      import(
        /* webpackChunkName: "point" */ "./components/audition/AuditionTicketBuyAds.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  {
    path: "/audition/ticket/buy/ads/info",
    name: "AuditionTicketBuyAdsInfo",
    component: () =>
      import(
        /* webpackChunkName: "point" */ "./components/audition/AuditionTicketBuyAdsInfo.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  {
    path: "/audition/result",
    name: "AuditionResult",
    component: () =>
      import(
        /* webpackChunkName: "point" */ "./components/audition/AuditionResult.vue"
      ),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('medicubo_token') === null) {
          return next('/login');
        } else {
          next();
        }
      }
  },
  {}
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
